<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <span>
          <b-img
            :src="appLogoImage"
            alt="logo"
            v-bind="mainProps"
          />
        </span>
        <h2 class="brand-text text-primary ml-1">
          AILex Tech
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('Login.title') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Login.cardText') }}
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                :label="$t('Login.form.emailLabel')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    :placeholder="$t('Login.form.emailPlaceholder')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      :placeholder="$t('Login.form.passPlaceholder')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('Login.form.buttonText') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span> {{ $t('Login.activation.cardText') }} </span>
            <b-button
              v-b-modal.modal-activate
              variant="secondary"
              block
            >
              {{ $t('Login.activation.buttonText') }}
            </b-button>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
      <b-modal
        id="modal-activate"
        cancel-variant="outline-secondary"
        :ok-title="$t('Login.activation.modal.okTitle')"
        :cancel-title="$t('Login.activation.modal.cancelTitle')"
        centered
        :title="$t('Login.activation.modal.title')"
        @ok="onBeginActivation"
      >
        <b-form>
          <b-form-group>
            <label for="ticket">Ticket:</label>
            <b-form-input
              id="ticket"
              v-model="activationTicket"
              type="text"
              :placeholder="$t('Login.activation.modal.placeholder')"
            />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BModal, VBModal, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { $themeConfig } from '@themeConfig'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  components: {
    BModal,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  data() {
    return {
      mainProps: {
        width: 30,
        height: 30,
      },
      status: '',
      password: process.env.VUE_APP_PRODUCTION ? '' : 'S1mpl3P455w0rd!',
      userEmail: process.env.VUE_APP_PRODUCTION ? '' : 'admin@ailexlegal.com',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      activationTicket: '',
      email,
    }
  },
  sockets: {
    reply(data) {
      console.log(data)
    },
    onJobStatusUpdate(data) {
      console.log(data)
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    testSocket() {
      console.log('Testing Socket!')
      this.$socket.emit('addJobToWaitingList', 2)
    },
    onBeginActivation() {
      this.$router.replace(`/activate?ticket=${this.activationTicket}`)
    },
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt.login({
            email: this.userEmail,
            password: this.password,
          })
            .then(response => {
              console.log('----[ RESPONSE 1 RECEIVED ]----')
              let userData = response.data
              useJwt.setToken(userData.accessToken)
              store.commit('app/SET_USER_INFO', userData)
              // useJwt.setRefreshToken(response.data.refreshToken)

              useJwt.getUserInfo().then(userInfo => {
                userData = { ...userData, ...userInfo.data }
                const translatedUserRole = this.$i18n.t(`Users.roles.${userData.role.toLowerCase()}`)
                console.log(userInfo)
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$ability.update(userData.abilities)
                // console.log(userData)
                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: this.$i18n.t('Toast.titles.login', { name: userData.display_name || userData.first_name }),
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: this.$i18n.t('Toast.texts.successLogin', { role: translatedUserRole }),
                      },
                    })
                  })
              }).catch(error => {
                console.log('----')
                console.log(error)
                console.log('----')
                this.$refs.loginForm.setErrors({ email: error.response.data.error })
              })
              // this.$ability.update(userData.ability)

              // ? This is just for demo purpose as well.
              // ? Because we are showing eCommerce app's cart items count in navbar
              // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)

              // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
              /*  */
            })
            .catch(error => {
              console.log('----')
              console.log(error)
              console.log('----')
              this.$refs.loginForm.setErrors({ email: error.response.data.error })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
